.services-section-individual {
    background-color: #f4f4f4;
    color: #333333;
    padding: 20px;
}

.services-flex-container-individual {
    display: flex;
}

.services-flex-container-reverse-individual {
    display: flex;
    flex-direction: row-reverse;
}

.services-flex-text-individual {
    flex: 0.55;
    font-family: 'Montserrat', sans-serif;
    color: #333;
}

.services-flex-text-individual p {
    line-height: 1.8;
    padding: 0px 5%;
    font-size: 25px;
    text-align: justify;
}

.services-flex-image-individual {
    flex: 0.45;
    display: flex;
    justify-content: center;
    align-items: center;
}

.services-flex-container-individual h1 {
    font-size: 40px;
    color: #307FE2;
    margin: unset;
    margin-top: 50px;
    text-align: center;
}

.services-flex-container-reverse-individual h1 {
    font-size: 40px;
    color: #307FE2;
    margin: unset;
    margin-top: 20px;
    text-align: center;
}

.services-section-individual img {
    width: 100%;
    border-radius: 10px;
}

.services-section-individual ul li {
    font-weight: bold; 
    margin-bottom: 8px; 
    color: #307FE2;
}

.services-section-individual ul li::before {
    font-weight: 800; 
    display: inline-block; 
    width: 1em; 
    color: #97D700;
}

.button-container-services-individual {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto; 
}

@media(min-width: 320px) {
    .services-flex-container-individual, 
    .services-flex-container-reverse-individual {
        flex-direction: column;
    }

    .services-flex-text-individual h1 {
        font-size: 28px;
    }

    .services-flex-text-individual p {
        font-size: 13px;
    }
}

@media(min-width: 480px) {
    .services-flex-container-individual, 
    .services-flex-container-reverse-individual {
        flex-direction: column;
    }

    .services-flex-text-individual h1 {
        font-size: 28px;
    }
    .services-flex-text-individual p {
        font-size: 13px;
    }
}

@media(min-width: 600px) {
    .services-flex-text-individual h1 {
        font-size: 32px;
    }

    .services-flex-text-individual p {
        font-size: 16px;
    }
}

@media(min-width: 768px) {
    .services-flex-text-individual h1 {
        font-size: 35px;
    }
    .services-flex-text-individual p {
        font-size: 18px;
    }

    .button-container-services-individual button {
        width: 130px;
        font-size: 15px;
        border-radius: 24px;
        padding: 8px 16px;
        line-height: 21px;
    }
}

@media(min-width: 1024px) {
    .services-flex-container-individual, 
    .services-flex-container-reverse-individual {
        flex-direction: column;
    }

    .services-flex-text-individual p {
        font-size: 25px;
    }
}

@media(min-width: 1280px) {
    .services-flex-container-individual, 
    .services-flex-container-reverse-individual {
        flex-direction: row;
    }

    .services-flex-text-individual h1 {
        font-size: 40px;
    }

    .services-flex-container-individual {
        display: flex;
    }
    
    .services-flex-container-reverse-individual {
        display: flex;
        flex-direction: row-reverse;
    }

    .services-flex-text-individual p {
        font-size: 22px;
    }
}

@media(min-width: 2000px) {
    .services-flex-text-individual p {
        font-size: 25px;
    }
}
