/* ------ GLOBAL STYLES ------ */

body, html {
    font-family: 'Montserrat', sans-serif;
     overflow-x: hidden;
}

/* ------ PORTADA SECTION ------ */

.portada {
    background: url('../../../assets/images/IMG_6156.jpg') center/cover no-repeat;
    height: calc(100vh - 114px);
    width: 100%;
}

/* ------ BOOK NOW SECTION ------ */

.book-now-portada {
    position: absolute;
    width: 477px;
    left: 99%;
    top: 34%;
    background: #307FE2;
    border-radius: 21px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    display: none;
}

.book-now-portada p {
    font-weight: 800;
    font-size: 58px;
    line-height: 64px;
    color: #FFFFFF;
    margin: 0 0 20px 0;
    text-align: center; 
}

.button-container-portada {
    display: block;
    position: relative;
}

.button-container-portada button {
    background-color: #ffff;
    transition: 0.2s;
    cursor: pointer;
    color: #000000;
    border: none;
    padding: 10px 20px;
    border-radius: 55px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 36px;
    text-align: center;
    width: 130px;
    margin-bottom: 30px;
}
  
.button-container-portada button:hover {
    background-color: #97D700;
}
