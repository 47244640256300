/* REVIEWS SECTION */
.reviews-section {
  text-align: center;
}

/* CONTAINER SECTION */
.reviews-button-container {
  background-color: #bdbdbd6e; 
}

/* HEADER */
.reviews-header {
  font-size: 60px;
  font-weight: 800;
  margin-bottom: unset;
  padding: 40px 0;
  margin-top: 0;
  letter-spacing: 1.5px;
  position: relative;
  background-color: #3080e2ea;
}

.reviews-header span {
  color: #fff;
}

/* REVIEWS CONTAINER */
.reviews-container {
  column-count: 3;
  column-gap: 20px;
  width: 100%;
  padding: 50px 10% 20px 10%;
  box-sizing: border-box;
}

/* REVIEW CARD */
.review-card {
  background: linear-gradient(145deg, #fff, #f4f4f4);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  margin-bottom: 20px; 
  break-inside: avoid;  
  padding: 25px 35px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%; 
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Montserrat', sans-serif;
  color: #bdbdbd;
}

.review-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
}

.stars {
  display: flex;
  gap: 4px;
  margin: 10px 0; 
}

.star {
  font-size: 18px;
  color: #ccc;
}

.star.filled {
  color: #ffb400;
}

.author {
  font-weight: bold;
  margin-top: 8px;
  font-size: 20px;
  color: #151515;
  text-align: left;
}

.body {
  color: #151515;
  line-height: 1.8; 
  text-align: left;
  overflow-x: hidden;
}

.time-ago {
  color: #888;
  font-size: 12px;
  text-align: left;
}

/* BUTTON */
.button-container {
  text-align: center;
  justify-content: center;
  padding-bottom: 20px;
}

@media (max-width: 1100px) {
  .reviews-header {
    padding-top: 50px;
  }
}

@media (max-width: 800px) {
  .reviews-header {
    font-size: 50px; 
  }

  .reviews-container {
    column-count: 2; 
  }
}

@media (max-width: 500px) {
  .reviews-header {
    font-size: 40px; 
  }

  .reviews-container {
    column-count: 1; 
  }
}