@import "~react-image-gallery/styles/css/image-gallery.css";

.component-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto; 
    background-color: #f4f4f4; 
    padding: 50px;
    overflow-x: hidden; 
}

.gallery-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4; 
    padding: 20px; 
    border-radius: 10px; 
    max-width: 100vw;
}

.gallery-title {
    font-size: 48px;  
    font-weight: 800;
    margin-bottom: 20px;
    color: #307FE2; 
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    font-family: 'Montserrat', sans-serif;
}

.image-gallery-image {
    max-width: 60%; 
    max-height: 60vh; 
    object-fit: cover; 
    border-radius: 15px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 1); 
}

.image-gallery-thumbnail {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 5px;
    max-width: 100px; 
    max-height: 75px; 
}

.thumbnail-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.image-gallery-thumbnail.active {
    border: 2px solid #97D700;
}

.image-gallery-thumbnail img {
    max-width: 100px; 
    max-height: 75px; 
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} 

.image-gallery-icon::before {
    color: #97D700; 
    font-size: 2em;
}

@media (min-width: 1025px) {
    .image-gallery-left-nav .image-gallery-icon,
    .image-gallery-right-nav .image-gallery-icon {
        display: block;
    }

    .image-gallery-icon::before {
        font-size: 1.5em; 
    }
}

@media (max-width: 1400px) {
    .image-gallery-left-nav {
        left: 14%; 
    }

    .image-gallery-right-nav {
        right: 14%; 
    }
}


@media (max-width: 1000px) {
    .image-gallery-left-nav .image-gallery-icon,
    .image-gallery-right-nav .image-gallery-icon {
        display: block;
    }

    .image-gallery-icon::before {
        font-size: 1.5em; 
    }

    .image-gallery-image {
        max-width: 50%; 
        max-height: 50vh; 
        border-radius: 12px; 
    }

    .image-gallery-left-nav {
        left: 20%; 
    }

    .image-gallery-right-nav {
        right: 20%; 
    }
}

@media (max-width: 750px) {
    .image-gallery-image {
        max-width: 40%; 
        max-height: 40vh; 
        border-radius: 12px; 
    }

    .image-gallery-left-nav {
        left: 25%; 
    }

    .image-gallery-right-nav {
        right: 25%; 
    }
}

@media (max-width: 500px) {
    .image-gallery-thumbnails-wrapper {
        width: calc((100px + 5px * 2) * 3); 
    }

    .gallery-title {
        font-size: 35px;
    }

    .image-gallery-icon::before {
        font-size: 1em; 
    }
}