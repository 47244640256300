.custom-button {
    background-color: #307FE2;
    transition: all 0.2s;
    cursor: pointer;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 55px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 36px;
    text-align: center;
    width: 130px;
  }
  
  .custom-button:hover {
    background-color: #97D700;
  }