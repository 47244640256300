/* Main container for the reviews */
.portada-reviews-homepage-container {
    padding: 92px 15px 92px 15px;
    position: relative;
    background: url(../../../assets/images/papa_van.jpg) center/cover no-repeat;
    background-color: #151515;
}

.portada-reviews-homepage-container:after {
    content: "";
    display: block;
    background: #307fe2db;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
}

/* Review Header */
.portada-review-header {
    font-size: 60px;
    font-weight: 800;
    padding: 75px 0;
    color: #307FE2;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    letter-spacing: 1.5px;
    position: relative;
    background-color: #307FE2;
    border-radius: 8px;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-image: linear-gradient(45deg, #97D700, #307FE2);
}

h1.portada-review-header {
    margin-top: 0;
    padding: 0;
    text-align: center;
    z-index: 1;
    color: #fff;
    font-size: 42px;
}

.portada-review-header:hover::after {
    animation: slideIn 0.3s forwards;
}

/* Review Card */
.portada-review-card-homepage {
    width: 80%;
    height: 250px;  
    overflow: hidden; 
    padding: 25px 35px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    font-family: 'Montserrat', sans-serif;
    margin: 10px auto;
    
}

.portada-review-card-homepage:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
}

/* Different color themes */
.portada-review-card-homepage:nth-child(1) {
    background-color: #fff;
    color: #000;
}

.portada-review-card-homepage:nth-child(2) {
    background-color: #1f1f1f;
    color: #dcdcdc;
}

.portada-review-card-homepage:nth-child(3) {
    background-color: #161616;
    color: #bdbdbd;
}

/* ------ REVIEW CONTENT STYLES ------ */

.portada-stars {
    display: flex;
    gap: 4px;
    margin: 10px 0; 
}

.portada-star {
    font-size: 18px;
    color: #ccc;
}

.portada-star.filled {
    color: #ffb400;
}

.portada-author {
    font-weight: bold;
    margin-top: 8px;
    font-size: 20px;
}

.portada-body {
    color: inherit;
    line-height: 1.8;
    font-size: 16px;
}

.portada-review-read-more {
    display: inline-block;
    margin-top: 8px;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
    color: inherit;
}

.portada-time-ago {
    color: #888;
    font-size: 12px;
}

/* ------ SLIDER ------ */

.slick-slider {
    max-width: 1350px;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
}

.slick-prev:before, .slick-next:before {
    font-size: 35px;
}

.slick-next {
    right: -30px;
}

.slick-prev {
    left: -40px;
    z-index: 2;
}

/* ------ BUTTON ------- */
.button-container-review {
    display: flex;
    justify-content: center;
    align-items: center; 
    margin-top: 30px;
}

.button-container-review a {
    text-decoration: none; 
    display: inline-block; 
    z-index: 1;
}

.button-container-review button {
    background-color: #ffff;
    transition: 0.2s;
    cursor: pointer;
    color: #000000;
    border: none;
    padding: 10px 20px;
    border-radius: 55px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 36px;
    text-align: center;
    width: 130px;
    justify-content: center;
    display: block; 
}
  
.button-container-review button:hover {
    background-color: #97D700;
}

@media (max-width: 1480px) {
    .portada-review-card-homepage {
        width: calc(66% - 20px); 
    }

    .slick-next {
        right: 3%; 
    }

    .slick-prev {
        left: 2%; 
    }
}

@media (max-width: 980px) {
    .portada-review-card-homepage {
        width: calc(50% - 20px); 
    }
    .slick-next {
        right: 18%; 
    }

    .slick-prev {
        left: 16%; 
    }
}

@media (max-width: 500px) {
    h1.portada-review-header {
        font-size: 30px;
    }

    .portada-author {
        font-size: 15px;
    }

    .portada-body {
        font-size: 13px;
        line-height: 1.5;
    }

    .portada-star {
        font-size: 13px;
    }

    .slick-next {
        right: 10%; 
    }

    .slick-prev {
        left: 6%; 
    }
}

@media (max-width: 350px) {
    .slick-next {
        right: 8%; 
    }

    .slick-prev {
        left: 3%; 
    }
}