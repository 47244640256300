/* General styles */
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

/* Navbar container styles */
.navbar-container {
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 100%;
}

/* Navbar styles */
.navbar {
  background-color: #FFFFFF;
  padding: 10px 0;
  border-bottom: 4px solid #307FE2;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
  min-height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav.navbar {
  padding: 10px 4%;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* Navbar left container styles */
.left-container {
  display: flex;
  align-items: center;
}

/* Logo styles */
.logo {
  display: inline-block;
  z-index: 2;
}

.logo img {
  left: 47px;
  top: 10px;
  background: #FFFFFF;
  border-bottom: 2px solid #97D700;
  margin-bottom: -79px;
  border-radius: 0px 0px 10px 10px;
}

/* Hamburger Icon Styles */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  margin-right: 20px;
}

.hamburger span {
  background-color: #000;
  height: 3px;
  width: 25px;
  margin: 2px 0;
  transition: all 0.3s ease;
}

/* Navbar list styles */
.nav-list ul {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0 4%;
}

.nav-list ul li {
  font-size: 18px;
  margin: 0 15px;
}

.nav-list ul li a {
  text-decoration: none;
  color: black;
  font-weight: 300;
  transition: color 0.3s;
}

.nav-list ul li a:hover {
  color: #97D700;
}

/* Navbar right container styles */
.right-container {
  display: flex;
  align-items: center;
}

.book-by-phone-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 0;
  width: 275px;
  height: 62px;
}

.phone-icon {
  font-size: 32px;
  display: flex;
  align-items: center;
}

.phone-number,
.book-now-letter {
  font-weight: 800;
  text-align: center;
  color: #97D700;
}

.phone-number {
  width: 100%;
  font-size: 23px;
}

.right-container .book-by-phone-container .book-number-letter-container .phone-number a {
  text-decoration: none;
  color: #97D700;
}

.book-now-letter {
  width: 150px;
  font-size: 15px;
  line-height: 30px;
}

.home-link {
  display: none;
}

/* NADGA LOGO */
.nadca-logo {
  max-width: 150px;
  max-height: 80px;
  margin-bottom: 10px;
  margin-top: 10px;
  vertical-align: middle;
  cursor: pointer;
}

.nadca-logo-link:hover .nadca-logo {
  transform: rotate(10deg);
  transition: transform 0.3s ease-in-out;
  margin-left: 10px;
}

/* Duct Clean Pro only when logo is hidden */
.duct-clean-pro-text {
  font-weight: 600;
  text-align: center;
  color: #97D700;
  display: none;
  font-size: 18px;
  margin-top: 5px;
}

@media (max-width: 950px) {
  .hamburger {
    display: flex;
  }

  .home-link {
    display: block;
  }

  .nav-list {
    display: none;
  }

  .nav-list.open {
    display: block;
    position: absolute;
    top: 80px;
    width: 150px;
    left: 10px;
    background: white;
    text-align: left;
    padding: 10px 0;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }

  .nav-list ul {
    display: block;
    padding: 0;
    margin: 0 auto;
  }

  .nav-list ul li {
    display: block;
    margin: 10px 0;
    text-align: center;
    transition: background 0.3s, color 0.3s;
  }

  .nav-list ul li:hover {
    background: #307FE2;
    color: #000;
  }

  .left-container .logo {
    display: none;
  }

  .duct-clean-pro-text {
    display: block;
  }

  .left-container {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nadca-logo {
    max-width: 125px;
    max-height: 70px;
    margin-bottom: 10px;
    margin-top: 10px;
    vertical-align: middle;
    cursor: pointer;
  }
}

@media (max-width: 430px) {
  .book-by-phone-container {
    width: auto; 
    justify-content: space-between; 
    margin-left: 10px;
  }

  .nadca-logo {
    max-width: 100px;
    max-height: 50px;
    margin-bottom: 10px;
    margin-top: 10px;
    vertical-align: middle;
    cursor: pointer;
  }

  .phone-icon {
    font-size: 28px;
    display: flex;
    align-items: center;
  }

  .phone-link {
    display: block;
    cursor: pointer;
    z-index: 1000;
    position: relative;
  }

  .phone-number,
  .book-now-letter {
    font-weight: 800;
    text-align: center;
    color: #97D700;
    font-size: 12px;
  }

  .phone-number {
    text-decoration: none;
    width: 100%;
    font-size: 15px;
  }

  .duct-clean-pro-text {
    font-size: 15px;
  }

  .phone-icon,
  .book-now-letter {
    display: none;
  }
}

@media (max-width: 400px) {
  .hamburger {
    display: flex;
  }

  .nav-list {
    display: none;
  }

  .home-link {
    display: block;
  }

  .nav-list.open {
    display: block;
    position: absolute;
    top: 80px;
    width: 150px;
    left: 10px;
    background: white;
    text-align: left;
    padding: 10px 0;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }

  .nav-list ul {
    display: block;
    padding: 0;
    margin: 0 auto;
  }

  .nav-list ul li {
    display: block;
    margin: 10px 0;
    text-align: center;
    transition: background 0.3s, color 0.3s;
  }

  .nav-list ul li:hover {
    background: #307FE2;
    color: #000;
  }

  .left-container .logo {
    display: none;
  }

  .left-container {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}