html, body {
    height: 100%;
    margin: 0;
}

.footer {
    margin-top: auto;
    display: flex;
    justify-content: space-around; 
    background-color: #307FE2;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    color: #fff;
    max-width: 100%; 
    margin: 0 auto; 
    box-sizing: border-box; 
}

.footer-column {
    width: 25%;
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    transition: all 0.3s ease;
    box-sizing: border-box; 
}

.footer-column h2 {
    color: #97D700;
    text-transform: uppercase; 
    margin: unset;
}

.footer-column ul {
    list-style-type: none;
    padding: 0;
}

.footer-column ul li {
    margin-bottom: 12px;
}

.footer-column ul li a.link {
    color: #fff;
    text-decoration: none;
    transition: all 0.3s ease; 
}

.footer-column ul li a.link:hover, 
.footer-column ul li a.link:active {
    color: #97D700;
    font-weight: 800;
    transform: scale(1.05); 
}

.footer-column ul.social-links li {
    display: flex;
    align-items: center;
}

.footer-column ul.social-links li a {
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
}

.footer-column ul.social-links li a svg {
    margin-right: 8px;
    transition: all 0.3s ease; 
}

.footer-column ul.social-links li a:hover, 
.footer-column ul.social-links li a:active {
    color: #97D700; 
    font-weight: 800;
}

.footer-column ul.social-links li a:hover svg, 
.footer-column ul.social-links li a:active svg {
    fill: #97D700;
    transform: scale(1.05) rotate(15deg); 
}

@media (max-width: 730px) {
    .footer {
        flex-direction: column; 
        align-items: center; 
    }

    .footer-column {
        width: 100%; 
        margin-bottom: 20px; 
    }
}