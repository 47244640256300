/* GLOBAL STYLES */
body, html {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    background-color: #f4f4f4; 
    box-sizing: border-box; 
    overflow-x: hidden;
}

@keyframes slideIn {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

/* SERVICES SECTION */
.services-section {
    text-align: center;
    background-color: #f4f4f4;
    padding: 0px 15px 0px 15px;
    max-width: 1200px; 
    margin: 20px auto; 
}

.services-header {
    font-size: 48px;  
    font-weight: 800;
    margin: 0;
    padding: 0;
    color: #307FE2;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    letter-spacing: 1.5px;
    position: relative;
    text-align: left;
    white-space: nowrap;
}

.services-header span {
    background: linear-gradient(90deg, #307FE2, #1f5bb8);
    -webkit-background-clip: text;
    color: transparent;
    padding: 5px 10px;
    border-radius: 5px;
}

.services-header:hover::after {
    animation: slideIn 0.3s forwards;
}

.services-header::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    height: 3px;
    width: 0;
    background: #1f5bb8;
    transition: width 0.3s;
}

.services-portada-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap; 
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    gap: 28px;
    align-items: stretch;
}

.service-cards-desc {
    flex: 1; 
    min-width: 390px;
    max-width: 390px; 
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    margin: 0; 
}

.service-cards-wrapper {
    flex: 2; 
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap; 
    align-items: center; 
    gap: 28px;
    width: 100%;
    padding: 0 5%;
    justify-content: space-between;
    align-items: center;
}

.service-card {
    background: #007ce3d4; 
    box-shadow: 5px 5px 15px #aaaaaa, -5px -5px 15px #ffffff;
    border-radius: 21px; 
    padding: 15px 30px; 
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    flex: 1 0 calc(33.333% - 56px); 
    max-width: calc(33.333% - 56px);
    min-width: 350px; 
    min-height: 250px;
    box-sizing: border-box;
    margin: 20px 2%;
}


.service-card:hover {
    transform: scale(1.05);
    box-shadow: 8px 8px 15px #aaaaaa, -8px -8px 15px #ffffff;
}

.service-title {
    font-size: 28px;
    font-weight: 700;
    white-space: nowrap;
    padding-bottom: 10px;
    max-width: 400px;
    margin: auto;
    color: white;
}

.service-description {
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    background: none;
    padding: 10px;
    border-radius: 10px;
    box-shadow: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
}

.info-line {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    color: #fff;
}

.info-line .info-text {
    text-align: left;
}

.info-line .checkmark {
    font-size: 22px;
    color: #97D700;
    margin-right: 10px;
}

.services-header-text, .description-text {
    line-height: 1.6;
    font-size: 18px;
    text-align: justify;
}

.view-more-button-desktop {
    display: block; 
}

.view-more-button-mobile {
    display: none; 
}

@media (max-width: 1400px) {
    .service-cards-wrapper {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .service-card {
        flex: 1 0 auto; 
        max-width: 350px; 
        min-width: 350px; 
        max-height: 250px;
        margin-bottom: 20px; 
    }
}

@media (max-width: 900px) {
    .service-cards-desc .services-header-text,
    .service-cards-desc .CustomButton {
        display: none;
    }

    .services-portada-container {
        flex-direction: column;
        align-items: center;
    }

    .service-cards-wrapper {
        flex: 1;
        flex-direction: column;
        align-items: center;
    }

    .service-card {
        flex: 1 1 100%; 
        max-width: 350px;
        min-width: 350px; 
        margin-bottom: 20px;
    }

    .services-header {
        margin-bottom: 20px;
        text-align: center; 
    }

    .view-more-button-desktop {
        display: none; 
    }

    .view-more-button-mobile {
        display: block; 
    }
}

@media (max-width: 500px) {
    .services-header {
        font-size: 35px;
    }

    .services-portada-container {
        flex-direction: column;
        align-items: center;
    }

    .service-card {
        flex: none; 
        width: 90%; 
        max-width: 325px; 
        min-width: 100px; 
        box-sizing: border-box;
    }

    .service-title {
        font-size: 25px;
    }

    .description-text {
        font-size: 15px;
    }
}

@media (max-width: 350px) {
    .service-title {
        font-size: 20px;
    }
}

